import { Link } from '@inertiajs/react';
import Avatar from '../Avatar';
import ToolTip from '../ToolTip';
import { arrayToString } from '@/utils';
import EntryStatusBadge from '../EntryStatusBadge';
import PlatformBadge from '../PlatformBadge';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Features from './Partials/Features';

export default function EventCard({ event }) {
	let {
		id,
		name,
		cover_card,
		community,
		region,
		entry_status,
		track,
		eventable,
		type,
		starts_at,
		round,
		total_rounds,
		platform,
		sessions,
		session_counts,
		status,
		game,
		feature,
		entry_type
	} = event;

	dayjs.extend(localizedFormat);

	return (
		<Link
			href={route('events.show', id)}
			className={`card bg-base-300 shadow-xl hover:-translate-y-2 duration-200 transition-all`}
		>
			<PlatformBadge platform={platform} />
			<div className="overflow-hidden rounded-t-2xl">
				<div className="relative border-b-2">
					{status === 'finished' ? (
						<div className="absolute inset-0 bg-neutral/75"></div>
					) : (
						<div className="absolute inset-0 bg-gradient-to-b from-neutral via-transparent to-neutral"></div>
					)}
					<div className="absolute w-full text-center">
						{status === 'finished' && (
							<div className="py-1 bg-white text-primary uppercase font-bold">
								Event Finished
							</div>
						)}
						{type === 'championship' && (
							<>
								<div className="py-1 uppercase bg-info">
									Championship Event
								</div>
								<div className="py-1 bg-white text-info">
									{eventable.name}
								</div>
							</>
						)}
						<div className="py-1 px-16">
							{track.name}
						</div>
					</div>

					<div className="absolute bottom-0 flex flex-col w-full">
						{game.logo !== null && (
							<div className="flex justify-end p-4">
								<img
									className="w-2/4"
									src={game.logo}
									alt={game.name}
								/>
							</div>
						)}

						{type === 'championship' && (
							<div className="w-full text-right bg-info py-1 px-4 border-t-2">
								Round {round} of{' '}
								{total_rounds}
							</div>
						)}
					</div>

					<figure className="aspect-square">
						<img
							src={cover_card}
							alt="Championship"
						/>
					</figure>
				</div>
			</div>

			<div
				className={`card-body relative p-5 ${
					status === 'finished' ? 'finished' : ''
				}`}
			>
				<div className="-mt-16 mb-4">
					<div className="-mt-12 py-2 flex gap-1 min-h-[44px]">
						{Object.keys(session_counts).map(
							(oneKey, i) => (
								<ToolTip
									key={oneKey}
									tip={`${oneKey} sessions: ${session_counts[oneKey]}`}
								>
									<span className="rounded-full bg-neutral w-7 h-7 flex justify-center items-center uppercase">
										{oneKey.charAt(0)}
									</span>
								</ToolTip>
							)
						)}
					</div>
					<Avatar
						width="w-24"
						url={community.avatar}
						className="drop-shadow-lg"
					/>
				</div>

				<div className="absolute top-4 right-4">
					<EntryStatusBadge
						entryStatus={entry_status}
					/>
				</div>

				<div className="flex h-14 items-center">
					<h2 className="card-title block uppercase text-ellipsis line-clamp-2">
						{name}
					</h2>
				</div>

				<div className="flex flex-col mt-4">
					<div className="flex items-center">
						<span className="material-icons-outlined text-primary text-4xl mr-2">
							event
						</span>
						<span className="text-gray-400 text-sm">
							{dayjs(starts_at).format('LLL')}
						</span>
					</div>
					<div className="flex items-center">
						<span className="material-icons-outlined text-primary text-4xl mr-2">
							{(entry_type === 'team' ? 'group' : 'person')}
						</span>
						<span className="text-gray-400 text-sm capitalize">
							{entry_type}
						</span>
					</div>
					<div className="flex items-center">
						<span className="material-icons-outlined text-primary text-4xl mr-2">
							language
						</span>
						<span className="text-gray-400 text-sm">
							{region &&
								arrayToString(region)}
						</span>
					</div>
				</div>

				{feature !== null &&
				<Features
					commentated={feature.commentated}
					stewards={feature.stewards}
					liveStreamed={feature.liveStreamed}
					prizes={feature.prizes}
				/>
				}

				<div className="card-actions w-full mt-4">
					<button
						className={`btn btn-block ${
							status === 'finished'
								? 'btn-accent'
								: 'btn-primary'
						}`}
					>
						{status === 'finished' ? (
							<span>View Results</span>
						) : (
							<span>View Event</span>
						)}
					</button>
				</div>
			</div>
		</Link>
	);
}
